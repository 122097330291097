// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/mango-ins/landing-front/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/builds/mango-ins/landing-front/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-buying-js": () => import("/builds/mango-ins/landing-front/src/pages/buying.js" /* webpackChunkName: "component---src-pages-buying-js" */),
  "component---src-pages-conditions-js": () => import("/builds/mango-ins/landing-front/src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-index-js": () => import("/builds/mango-ins/landing-front/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("/builds/mango-ins/landing-front/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-login-js": () => import("/builds/mango-ins/landing-front/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-newindex-js": () => import("/builds/mango-ins/landing-front/src/pages/newindex.js" /* webpackChunkName: "component---src-pages-newindex-js" */),
  "component---src-pages-payments-js": () => import("/builds/mango-ins/landing-front/src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-product-js": () => import("/builds/mango-ins/landing-front/src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-vacancy-js": () => import("/builds/mango-ins/landing-front/src/pages/vacancy.js" /* webpackChunkName: "component---src-pages-vacancy-js" */)
}

